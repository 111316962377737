/*** Importations *********************************************************************************/

// import objectFitImages from 'object-fit-images/dist/ofi.es-modules.js';
import smoothscroll from 'smoothscroll-polyfill';//4ko

// Libraries

import GLightbox from 'glightbox/dist/js/glightbox.js';//43ko
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6.js';//- de 1ko
import LazyLoad from 'vanilla-lazyload/dist/lazyload.esm.js';//5ko
import Swiper, { Navigation/*, Autoplay, Pagination, EffectFade*/ } from 'swiper/core';// 66ko
import { Gradient } from 'gradientAnimation';// 24ko

// Scripts Nubee

import SmoothScroller from 'partials/smooth-scroller.js';// 1ko
import * as functions from 'partials/functions.js';// 1ko
import 'partials/scroll-interval.js';// - de 1ko
import 'partials/target-blank-security.js';// - de 1ko
import placeholderLabelUpdate from 'partials/placeholder-label.js';// - de 1ko
import GLightboxNubee from 'partials/glightbox-nubee.js';// 16ko
import MobileMenu from 'partials/mobile-menu.js';// 7ko
import imgCheck from 'partials/img-check.js';// - de 1ko
import InfiniteScrollSimple from 'partials/infinite-scroll-simple.js';// 13ko
import * as cookiesBar from 'partials/cookies-bar.js';

// On set toutes les variables globales et évènements qui les modifient ici
import * as globalVarFunctions from 'partials/global-variables.js';// 4ko

(function() {
	/*** Application des polyfills ********************************************************************/

	(function() {
		// objectFitImages();
		smoothscroll.polyfill();

		if( 'function' != typeof window.Event ) {
			window.Event = CustomEvent;
		}
	}) ();


	/*** Variables globales ***************************************************************************/
	// global-variables.js
	// Chaînes traduisibles et autres variables provenant de theme-functions : window.nubeeGlobals.i18n.monPlugin.nomDeChaine

	globalVarFunctions.setGlobalVariables();

	/*** Animation de dégradé du hero de l'accueil ***********************************************/
	(function() {
		document.addEventListener("DOMContentLoaded", function() {
			const gradientWrapper = document.getElementById( 'gradient-animation' );

			let gradientCanvas = document.createElement('canvas');

			gradientCanvas.classList.add( 'gradient-animation__canvas' );
			gradientCanvas.setAttribute( 'width', '375' );
			gradientCanvas.setAttribute( 'height', '667' );
			gradientCanvas.setAttribute( 'role', 'presentation' );

			gradientWrapper.appendChild( gradientCanvas );

			if( gradientCanvas !== undefined ) {
				let gradient = new Gradient( gradientCanvas, ['#0000D6', '#27C6C4', '#FA2F40', '#0000D6'], {
					seed: 12,
					// amplitude: 1,
					scaleY: 0.5,
					scaleX: 1.4,
				});

				// gradientWrapper.style.opacity = 1;
				gradientWrapper.classList.remove('not-loaded');
				gradientCanvas.style.display = 'block';
				gradientCanvas.style.opacity = 1;
			}

		});
	}) ();


	/*** Les liens avec ancre qui mènent vers la même page deviennent seulement des ancres *******************/
	// Requiert functions.urlsAreSamePage
	// Requiert functions.arrayAllEqual

	(function() {
		let linksWithHash = document.querySelectorAll('a[href*="#"]');

		linksWithHash.forEach( function( thisLink ) {
			if( functions.urlsAreSamePage({ urls: [ thisLink.href, window.location.href ] }) ) {
				thisLink.href = thisLink.hash;
			}
		} );
	}) ();


	/*** Défiler doucement les liens ancres *******************************************************************/
	// Requiert window.smoothScroller (smooth-scroller.js), initialisé dans global-variables.js
	// Ajuster la variable globale window.fixedElHeight

	(function() {
		if( window.smoothScroller ) {
			let smoothScrollLinks = document.querySelectorAll('a[href^="#"]:not([href="#"]):not(.no-smooth-scroll):not([target])');

			smoothScrollLinks.forEach( function( thisEl ) {
				if( functions.scriptsOk( thisEl ) ) {
					thisEl.addEventListener( 'click', function( event ) {
						if( 1 == event.which ) {
							event.preventDefault();
							window.smoothScroller.scrollTo({ target: thisEl.hash });
						}
					} );
				}
			});
		}
	}) ();

	/*** Défile doucement au chargement d'une URL avec ancre ***************************************************/
	// Évènement "hashblocked" pour dire que le jump par défaut vient d'être bloqué
	// Évènement "initialscrollstart" pour dire qu'on s'apprête à faire le scroll
	// Évènement "initialscrollend" pour dire que la page est chargée ET l'ancre active est scrollée
	// S'il n'y a pas d'ancre (#) active, l'évènement est lancé dès le chargement.
	// Requiert window.smoothScroller (smooth-scroller.js), initialisé dans global-variables.js

	(function() {
		let origHash = window.location.hash;
		let hashBlockedEvent = new Event( 'hashblocked' );
		let scrollStartEvent = new Event( 'initialscrollstart' );
		let scrollEndEvent = new Event( 'initialscrollend' );

		if( origHash ) {
			let targetEl = document.querySelector( origHash );

			if( targetEl ) {
				window.addEventListener( 'hashblocked', function() {
					window.addEventListener( 'smoothscrollend', function initialScrollEnd() {
						window.dispatchEvent( scrollEndEvent );
						window.removeEventListener( 'smoothscrollend', initialScrollEnd );
					} );

					window.dispatchEvent( scrollStartEvent );

					window.smoothScroller.scrollTo({
						target: origHash,
						historyState: history.state,
						historyAction: 'replace',
						// debug: true,
					});
				} );


				// Blocage du jump du navigateur par défaut
				setTimeout( function() {
					functions.changeHashWithoutScrolling( null, history.state, 'replace' );
					window.scrollTo( 0, 0 );
					window.dispatchEvent( hashBlockedEvent );
				}, 1 );
			} else {
				window.dispatchEvent( scrollEndEvent );
			}
		} else {
			window.dispatchEvent( scrollEndEvent );
		}
	}) ();


	/*** Ne rien faire au clic sur un lien vide (#) ************************************************************/

	(function() {
		document.addEventListener( 'click', function( event ) {
			if( 1 == event.which ) {
				let linkClicked = event.target.closest('a');
				let theHref = linkClicked ? linkClicked.getAttribute('href') : null;

				if( linkClicked && ( ! theHref || '#' == theHref ) && functions.scriptsOk( linkClicked ) ) {
					event.preventDefault();
				}
			}
		} );
	}) ();


	/*** Images et backgrounds différents selon la taille de l'écran ********************************/
	// Requiert img-check.js

	(function() {
		if( 'function' == typeof imgCheck ) {
			window.addEventListener( 'resize', imgCheck );
			imgCheck();
		}
	}) ();


	/*** Lazy load des images et backgrounds *******************************************************************/
	// Requiert vanilla-lazyload

	(function() {
		let loadedEvent = new Event( 'lazyloaded' );

		window.siteLazyLoad = new LazyLoad({
			elements_selector: '.lazy',
			class_loading: 'lazy-loading',
			class_loaded: 'lazy-loaded',
			class_error: 'lazy-error',
			// use_native: true,
			callback_loaded: function( element ) {
				element.dispatchEvent( loadedEvent );
			},
		});
	}) ();


	/*** Antispam maison sur les commentaires ****************************************************************/
	// À moins d'avoir activé le JS, la validation PHP refusera le commentaire.

	(function() {
		let commentForms = document.querySelectorAll('#commentform');

		commentForms.forEach( function( thisForm ) {
			thisForm.addEventListener( 'submit', function() {
				let legitInput = document.createElement( 'input' );
				legitInput.setAttribute( 'type', 'hidden' );
				legitInput.setAttribute( 'name', 'is_legit' );
				legitInput.value = 1;

				thisForm.appendChild( legitInput );
			} );
		} );
	}) ();

	/*** Mettre les label Gravity Forms en mode focus quand ils ont une valeur *************************/
	(function() {
		document.querySelectorAll( '.gfield' ).forEach( function( thisFormRow ) {
			thisFormRow.querySelectorAll( 'input, textarea, select' ).forEach( function( thisField ) {
				if( thisField.value !== '' ) { thisFormRow.classList.add( 'has-value' ); }

				thisField.addEventListener( 'change', function(){
					if( thisField.value !== '' ) {
						thisFormRow.classList.add( 'has-value' );
					} else {
						thisFormRow.classList.remove( 'has-value' );
					}
				} );
			} );
		} );
	}) ();

	/*** Désactiver le bouton après un click dans les formulaires Gravity Forms *************************/
	/*(function() {
		document.querySelectorAll( '.gform_button' ).forEach( function( thisButton ) {
			thisButton.addEventListener( 'click', function() {
				thisButton.closest('form').submit();
				thisButton.disabled = true;
			} );
		} );
	}) ();*/


	/*** Menu mobile maison **********************************************************************************/

	(function() {
		new MobileMenu({
			menuSel: '.mobile-menu',
			buttonSel: '.mobile-menu-button',
			// debug: true,
			dropdownParentLinkActive: false,

			// Si on agrandit la fenêtre dans ce media query, le menu se ferme automatiquement.
			closeMediaQuery: false,

			// viewportMax: { width: 699 },
		});
	}) ();


	/*** InfiniteScrollSimple - Version maison plus légère *************************************************/
	// Requiert infinite-scroll-simple.js
	// Requiert regenerator-runtime (Babel veut ça pour les fonctions async)
	// Voir le début du fichier pour des events sur lesquels vous hooker

	(function() {
		let infiniteContainers = document.querySelectorAll('.iss-container');

		infiniteContainers.forEach(function( thisEl ) {
			if( functions.scriptsOk( thisEl ) ) {
				new InfiniteScrollSimple({
					container: thisEl,
					useInner: true,
					animate: true,
					// debug: true,
				});
			}
		} );

	}) ();


	/*** Sliders Swiper Partners ********************************************************************************/
	// Requiert Swiper

	(function() {
		Swiper.use( [ Navigation ] );

		new Swiper( '.slider-navigation-one-line .swiper', {
			speed: 400,
			spaceBetween: 25,
			slidesPerView: 1.25,
			direction: 'horizontal',
			loop: false,
			touchEventsTarget: 'wrapper',
			preventInteractionOnTransition: true,
			preventClicks: false,
			preventClicksPropagation: false,
			effect: 'slide',
			preloadImages: true,
			shortSwipes: true,
			lazy: {
				loadPrevNext: true,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			breakpoints: {
				700: { slidesPerView: 2.25, spaceBetween: 50, },
				1000: { slidesPerView: 2.25, spaceBetween: 82, },
				2000: { slidesPerView: 3.25, },
  			},
			on: {
				beforeLoopFix: function( ) {
					window.siteLazyLoad.update();
				}
			},
		});

	}) ();

	/*** Empêcher la taille d'un élément de se réajuster une fois le chargement terminé ***********************/

	(function() {
		let lockedHeightEls = document.querySelectorAll('.lock-height-on-load');

		window.addEventListener( 'load', function() {
			lockedHeightEls.forEach( function( thisEl ) {
				if( functions.scriptsOk( thisEl ) ) {
					thisEl.style.height = thisEl.offsetHeight + 'px';
					thisEl.style.minHeight = 0;
					thisEl.style.maxHeight = 'none';
				}
			} );
		} );
	}) ();

	/*** smoothFadeIn - effet d'apparition lorsque dans le viewport *****************************************/
	// Requiert functions.smoothFadeIn
	// Options dans general-styles.scss

	(function() {
		let fadeInItems = document.querySelectorAll('.progressive-fade-in');

		fadeInItems.forEach( function( thisEl ) {
			functions.progressiveFadeIn( thisEl );

			window.addEventListener( 'scrollinterval', function() {
				functions.progressiveFadeIn( thisEl );
			} );
		} );
	}) ();

	/*** Afficher un thumb au survol des projets *******************************************************/

	/*(function(){
		functions.hoverReveal();
	}) ();*/

	/*** Lightbox et popups avec GLightbox ******************************************************************/
	// Requiert GLightbox (glightbox)
	// Requiert glightbox-nubee.js

	(function() {
		// Éléments seuls.
		let lightboxEls = document.querySelectorAll('.lightbox');
		lightboxEls.forEach( function( thisEl ) {
			if( functions.scriptsOk( thisEl ) ) {
				new GLightboxNubee({
					links: thisEl,
					// debug: true,
				});
			}
		} );

		// Galeries. (Attention de donner une classe différente à chaque galerie de la page.)
		// Utilisez data-lightbox-element-class="ma-classe" sur .lightbox-gallery pour l'identifier.
		let lightboxGals = document.querySelectorAll('.lightbox-gallery');
		lightboxGals.forEach( function( thisGal ) {
			if( functions.scriptsOk( thisGal ) ) {
				new GLightboxNubee({
					galleryParent: thisGal,
					// debug: true,
				});
			}
		} );

		// On peut maintenant accéder aux instances à partir des liens ou du parent de la galerie :
		// <un lien lightbox ou un div galerie>._GLightboxNubee
		// <un lien lightbox ou un div galerie>._GLightbox
	}) ();

	/*** Gestion des préférence de cookies ***************************************/
	(function() {
		window.acceptedCookies = cookiesBar.getCookie('acceptedCookies');

		if( 'function' == typeof cookiesBar.cookieConsent ) {
			cookiesBar.cookieConsent();
		}
	}) ();

}) ();