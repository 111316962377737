function ScrollClassAgent( args ) {
	let that = this;

	args = args || {};
	args.classes = args.classes || [];
	args.classTarget = args.classTarget || document.body;
	args.debug = args.debug || false;

	that.args = args;

	that.init = function() {
		that.updateClasses();
	};

	that.updateClasses = function() {
		let classesToChange = [];

		that.args.classes.forEach( function( thisClass ) {
			// mustHaveClass détermine si la classe doit être là ou pas au bout du processus.
			let mustHaveClass = true;
			let scrollDirection = thisClass.scrollDirection || 'top';
			let hasClass = that.args.classTarget.classList.contains( thisClass.class );

			if( thisClass.hasOwnProperty( 'minimum' ) ) {
				mustHaveClass = (
					mustHaveClass
					&& ( window.curScroll[scrollDirection] >= thisClass.minimum )
				);
			}

			if( thisClass.hasOwnProperty( 'maximum' ) ) {
				mustHaveClass = (
					mustHaveClass
					&& ( window.curScroll[scrollDirection] < thisClass.maximum )
				);
			}

			if( thisClass.hasOwnProperty( 'scrollChange' ) && 'positive' == thisClass.scrollChange ) {
				mustHaveClass = (
					mustHaveClass
					&& ( window.curScroll[scrollDirection] > window.lastScroll[scrollDirection] )
				);
			}

			if( thisClass.hasOwnProperty( 'scrollChange' ) && 'negative' == thisClass.scrollChange ) {
				mustHaveClass = (
					mustHaveClass
					&& ( window.curScroll[scrollDirection] < window.lastScroll[scrollDirection] )
				);
			}

			// Il faut mettre/enlever la classe à l'élément. On l'ajoute à l'array de traitement.
			if( hasClass != mustHaveClass ) {
				thisClass.newState = ( ! hasClass );
				classesToChange.push( thisClass );
			}
		} );

		that.changeClasses( classesToChange );
	};

	that.changeClasses = function( classesToChange ) {
		if( classesToChange ) {
			let changedEvent = new Event( 'scrollclasseschanged' );

			// Changement de chacune des classes
			classesToChange.forEach( function( thisClass ) {
				that.args.classTarget.classList.toggle( thisClass.class );
			} );

			// On lance l'évènement scrollclasseschanged une fois les classes changées.
			that.args.classTarget.classesChanged = classesToChange;
			that.args.classTarget.dispatchEvent( changedEvent );
		}
	};

	that.init();
}

export default ScrollClassAgent;