/*** Sécurité pour les liens "nouvel onglet" **************************************************************/

(function() {
	let eventNames = [ 'mouseup', 'mousedown', 'mousemove' ];
	window.lastWindowOpened = 0;

	eventNames.forEach( function( eventName ) {
		document.addEventListener( eventName, function( event ) {
			if( 1 == event.which || 2 == event.which ) {
				let linkClicked = event.target.closest('a:not(.no-tab-security)');
				let linkTarget = linkClicked ? linkClicked.getAttribute('target') : null;
				let newWindow;
				let targetRegex = new RegExp( /^(_blank|_new)$/, 'i' );

				if( linkTarget && targetRegex.test( linkTarget ) ) {
					let linkUrl = linkClicked.getAttribute('href');

					switch( event.eventName ) {
						case 'mousedown':
							linkClicked.dragging = false;
							linkClicked.clicking = true;
							break;

						case 'mousemove':
							if( linkClicked.clicking ) {
								linkClicked.dragging = true;
							}
							break;

						case 'mouseup':
						default:
							event.preventDefault();

							if( linkClicked.clicking && ! linkClicked.dragging ) {
								newWindow = window.open( linkUrl, 'targetBlankWindow_' + window.lastWindowOpened );

								++ window.lastWindowOpened;

								if( newWindow ) {
									newWindow.opener = null;
								}
							}

							linkClicked.clicking = false;
							linkClicked.dragging = false;
							break;
					}
				}
			}
		} );
	} );
}) ();