/*** Évènement scrollinterval - comme scroll mais au maximum aux 50 ms. *************************/
// Plus performant qu'un évènement scroll. Se hooker là-dessus quand c'est pas nécessaire d'être au pixel près.
// scrollinterval : Se lance toutes les 100 ms à condition qu'on ait scrollé depuis la dernière fois.
// Utilisation : window.addEventListener( 'scrollinterval', function() { /* Votre code */ } );
// scrollintervalbefore et scrollintervalafter : Se lancent toutes les X ms peu importe quoi

let scrollEventBefore = new Event( 'scrollintervalbefore' );
let scrollEvent = new Event( 'scrollinterval' );
let scrollEventAfter = new Event( 'scrollintervalafter' );

setInterval( function() {
	window.dispatchEvent( scrollEventBefore );

	if( 'undefined' == typeof window.lastScroll || window.curScroll.top != window.lastScroll.top ) {
		window.dispatchEvent( scrollEvent );
	}

	window.dispatchEvent( scrollEventAfter );
}, 100 );